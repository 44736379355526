import Menu from "../components/Menu";
import Container from 'react-bootstrap/Container';
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import GradientDivider from "../components/GradientDivider";

import img1 from "../components/images/robotic.webp";

import img4 from "../components/images/automa.webp";
import { Component } from "react";

class Contact extends Component {

  

  render(){
    return(
      <>
      <Container>
      <Menu/>
       <div className="container-fluid p-5">
        <Row xs={1} md={2} lg={2}>
          <Col>
            <Stack gap={3} className="pb-5 text-sm-center text-md-start">
            <h1>Kontakt</h1>
              <div className='text-color-theme2'>APO-TECH Paweł Orłowski</div>
              <div><a href="tel:+48 577 607 177" className='text-decoration-none bi'>+48 577 607 177</a></div>
              <div><a href='mailto:biuro@apo-tech.pl' className='text-decoration-none bi'>biuro@apo-tech.pl</a></div>
              <div>Wola Bukowska 6A</div>
              <div>21-413 Serokomla</div>
              <div>NIP: PL8251868243</div>
            </Stack>
          </Col>

          <Col className="text-sm-center text-md-end text-lg-center">
            <Image src={img4} width={200}></Image>
          </Col>

        </Row>
          
          <GradientDivider/>
         
          
        
          
          
          
        </div>
        
      </Container>
      <Image fluid src={img1} className="contactimage bg-dark-overlay "/>
       
       </>
    );
  }
  };
  
  export default Contact;
  