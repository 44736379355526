import Carousel from 'react-bootstrap/Carousel';
import CarouselImage from './CarouselImage';
import img1 from './images/car1.webp';
import img2 from './images/car2.webp';
import img3 from './images/car3.webp';

function CarouselAPO() {
  return (
    <Carousel className='bg-dark-overlay'>
      <Carousel.Item >
        <CarouselImage imgSrc={img1} />
        <Carousel.Caption >
          <h1 >Automatyka przemysłowa</h1>
          <h4>komponenty najlepszych producentów</h4>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselImage imgSrc={img2} />
        <Carousel.Caption>
        <h1 >Wiedza i doświadczenie</h1>
          <h4>z którym chętnie się dzielimy</h4>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselImage imgSrc={img3} />
        <Carousel.Caption>
        <h1 >Indywidualne podejście do klienta</h1>
          <h4>do każdego tematu podchodzimy bardzo starannie</h4>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselAPO;