import Jumbotron from "../components/Jumbotron";
import Menu from "../components/Menu";
import Container from "react-bootstrap/Container";

const Aboutus = () => {
    return(
      <>
      <Container>
      <Menu/>   
      <div className="container-fluid p-5 text-center">
        <h1>Kim jesteśmy?</h1>
        <p>Apo-tech</p>
        <h3>Wizja</h3>
        <h3>Misja</h3>
        <h3>Wartości</h3>
       </div>
       </Container>
      <Jumbotron headerText="Some header" plainText="this is a paragraph" bgTheme="bg-theme1"/>
      <div className="container-fluid p-5 text-center">
        <h1>Kim jesteśmy?</h1>
        <p>Apo-tech</p>
        <h3>Wizja</h3>
        <h3>Misja</h3>
        <h3>Wartości</h3>
       </div>
      <Jumbotron headerText="22Some header" plainText="22this is a paragraph" bgTheme="bg-theme2"/>
      <div className="container-fluid p-5 text-center">
        <h1>Kim jesteśmy?</h1>
        <p>Apo-tech</p>
        <h3>Wizja</h3>
        <h3>Misja</h3>
        <h3>Wartości</h3>
       </div>
        
       </>
    );
  };
  
  export default Aboutus;
  