import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import logoFooter from '../components/images/logo-white.svg';

import CookieConsent from "react-cookie-consent";

function Footer(){
    return(
        <Container fluid className='p-5 bg-theme1 text-white pb-0 text-center text-md-start'>
        <Row xs={1}>
        <p><Image src={logoFooter} style={{ width: '12rem' }} /></p>
        </Row>
        <Row xs={1} md={3}>
            <Col className="pt-3 pb-3">
       
            <Stack gap={3}>
                <div className='text-color-theme2'>APO-TECH Paweł Orłowski</div>
                <div><a href="tel:+48 577 607 177" className='text-decoration-none text-white bi'>+48 577 607 177</a></div>
                <div><a href='mailto:biuro@apo-tech.pl' className='text-decoration-none text-white bi'>biuro@apo-tech.pl</a></div>
                <div>Wola Bukowska 6A</div>
                <div>21-413 Serokomla</div>
                <div>NIP: PL8251868243</div>
                
            </Stack>
            </Col>

            <Col className="pt-3 pb-3">
      
            <Stack gap={3}>
                <div className='text-color-theme2'>Mapa strony:</div>
                <div><Link to="/" className='text-decoration-none text-white bi' onClick={() => window.scrollTo(0, 0)}>Strona główna</Link></div>
                <div><Link to="/offer" className='text-decoration-none text-white bi' onClick={() => window.scrollTo(0, 0)}>Oferta</Link></div>
                <div><Link to="/producers" className='text-decoration-none text-white bi' onClick={() => window.scrollTo(0, 0)}>Producenci</Link></div>
                <div><Link to="/partners" className='text-decoration-none text-white bi' onClick={() => window.scrollTo(0, 0)} >Zaufali nam</Link></div>
              {/*  <div><Link to="/aboutus" className='text-decoration-none text-white bi'>O nas</Link></div>*/}
                <div><Link to="/contact" className='text-decoration-none text-white bi' onClick={() => window.scrollTo(0, 0)} >Kontakt</Link></div>
            </Stack>
         
            </Col>

            <Col className="pt-3 pb-3">
            <Stack  gap={3} >
                <div className='text-color-theme2'>Znajdź nas na</div>

                <div className='d-flex justify-content-center justify-content-md-start'>
                   
                        <div className='me-3'>
                            <Link target='blank' to='https://www.facebook.com/profile.php?id=61551594424863&locale=pl_PL' className='text-white'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                            </svg>
                            </Link>
                        </div>
                        <div >
                            <Link className='text-white' target='blank' to="https://www.linkedin.com/company/apo-tech-pawe%C5%82-or%C5%82owski/?viewAsMember=true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                            </svg>
                            </Link>
                        </div>
                   
                </div>
                
                <div className='text-color-theme2'>Przydatne linki</div>
                
                <div>
                <Link to="https://automa.net/company/apo-tech-pawel-orlowski/?utm_source=automa_net&utm_medium=badge&utm_campaign=verified_supplier" target="_blank"> 
                    <Image fluid width={200} src="https://automa.net/wp-content/uploads/2023/09/Verified-Supplier-Automa.Net_.png" alt="Automa.Net Verified Supplier"/> 
                </Link>

                </div>
            </Stack>
            </Col>
        </Row>
        <Row xs={1} className='pt-5 pb-1 mb-0'>
            <Col>
            <p className='text-center text-muted pb-0 mb-0 fw-light fs-6 '>	
            &copy; Copyright APO-TECH Paweł Orłowski 2023 - Designed by <Link to="https://stercon.pl" className='text-decoration-none text-muted'>Stercon</Link></p>
            </Col>
            
        </Row>
        <CookieConsent 
    location="bottom"
    buttonText="Akceptuję"
    cookieName="myAwesomeCookieName2"
    style={{ background: "#2B373B" }}
    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
    expires={150}
  >
    Nasza strona wykorzystuje pliki cookies m.in. w celu poprawienia jej dostępności, personalizacji, zbierania danych, dotyczącego ruchu na stronie. Każdy może sam decydować o tym czy dopuszcza pliki cookies, ustawiając odpowiednio swoją przeglądarkę.{" "}
    <span style={{ fontSize: "10px" }}>Więcej informacji znajdziesz w pliku polityka ciasteczek.</span>
  </CookieConsent>  
    </Container>
  
    );
}
export default Footer;