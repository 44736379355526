import Image from 'react-bootstrap/Image';

function CarouselImage(props){
    return(
       
          <Image src={props.imgSrc} fluid className='carouselimage'/>
     
    );


}
export default CarouselImage;