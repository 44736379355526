import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image";


import "./Gridgallery.css";

function Gridgallery(props){
    
    const { images } = props.imageData;
    const path = props.imagesPath;

    return(
        <>
        <Row xs={2} md={3} lg={4} className="text-center pb-5">
        {images.map((imageName, index) => (
            <Col key={index} className='border d-flex align-items-center simple-hover'>
              <Image src={require(`${path}${imageName}`)} alt={`Logo ${index + 1} - ${imageName}`} fluid className="p-4" />
              
            </Col>
          ))}
       </Row>
        </>
    );
}
export default Gridgallery;