
import { Container } from 'react-bootstrap';
import './Jumbotron.css';

function Jumbotron(props){
    const baseClass = "text-white p-5 text-center ";
    const propClass = props.bgTheme;
    const finalClass = `${baseClass}${propClass}`;
    return(
        
            <Container fluid className={finalClass}>
                <h2>{props.headerText}</h2>
                <p>{props.plainText}</p>
            </Container>
        
    );
}

export default Jumbotron;