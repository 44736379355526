import ApotechLogo from './logo.png';
import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GearLogo from '../components/images/carlogo4.webp';
import { Stack } from 'react-bootstrap';


function Logo() {
    return (
        <div className='bg-theme4'>
        <Row>
            <Col lg={8}>
                <Row className='mb-5 mt-5 text-center'>
                    <Link to="/">
                    <Image src={ApotechLogo} fluid  className='p-5' width="450"/>
                    </Link>  
                </Row>
                <Row>
                    <Col sm={8} md={7} lg={6} className='bg-theme1 text-white p-4 fs-5 fw-bolder text-center text-lg-end border-gradient'>
                    Automatyka przemysłowa
                    
                    </Col>
               
                    <Col sm={4} md={5} lg={4} className='text-center text-lg-end p-4 d-none d-sm-block'>
                        <Stack >
                            <div><a href="tel:+48 577 607 177" className='text-decoration-none text-color-theme1'>+48 577 607 177</a></div>
                            <div><a href='mailto:biuro@apo-tech.pl' className='text-decoration-none text-color-theme1'>biuro@apo-tech.pl</a></div>

                        </Stack>
                
                    </Col>
                </Row>

          

            </Col>
            <Col className='d-flex justify-content-end' >
            
                
            <Image src={GearLogo} fluid className='headerimage d-none d-lg-block'/>
            
            
            </Col>
            
        </Row>
        
        </div>
        
    );
  }
  
  export default Logo;