import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

function CarouselFadeExample(props) {

    const { images } = props.imageData;
    const path = props.imagesPath;

  return (
    <Carousel data-bs-theme="dark" className='text-center' indicators={false}>

        {images.map((imageName, index) => (
            <Carousel.Item  key={index} > 
            <div className='mb-5'>
            <Image src={require(`${path}${imageName}`)} alt={`Logo ${index + 1} - ${imageName}`} fluid className="p-5" style={{maxHeight:200}}/>
            </div>              
               
      
            </Carousel.Item>
            
          ))}
      
    </Carousel>
  );
}

export default CarouselFadeExample;