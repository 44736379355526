import Image from "react-bootstrap/esm/Image";

import './Singleoffer.css';
import { Badge } from "react-bootstrap";

function Singleoffer(props){
    const offerImage = props.offerImage;
    const offerHeading = props.offerHeading;
    const offerText = props.offerText;

     // Using the map method to create React elements for each string
     const offerTextElements = offerText.map((currentString, index) => (
        <Badge className="p-1 m-1 fs-6" bg="secondary" key={index}>{currentString}</Badge>
      ));

    return(
        <div className="pb-5 shadow p-2 mb-5">
        <Image src={offerImage} fluid rounded className="pb-5 singleofferimage" />
        
        <h2 className="pb-2">{offerHeading}</h2>
        <p className="pb-5">
      
            {offerTextElements}
          
        </p>
        
        </div>
    );
}
export default Singleoffer;