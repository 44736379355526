import Menu from "../components/Menu";
import Container from "react-bootstrap/Container";
const NoPage = () => {
    return(
      <>
      <Container>
      <Menu/>
       <div className="container-fluid p-5">
        <h1>Błąd: 404</h1>
        <p>Strona o podanym adresie nie istnieje.</p>

       </div>
      </Container>
      </>
    );
  };
  
  export default NoPage;
  