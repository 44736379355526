import Menu from "../components/Menu";
import Container from "react-bootstrap/Container";

import imageData from '../components/images/customers/imageData.json';
import CarouselCustomers from "../components/CarouselCustomers";

const Partners = () => {
    return(
      <>
      <Container>
      <Menu/>
       <div className="container-fluid p-5 text-center">
        <h1>Zadowoleni klienci</h1>
        
       </div> 
       <CarouselCustomers imageData={imageData} imagesPath="./images/customers/"/> 
       
       
               
      </Container>     
       </>
    );
  };
  
  export default Partners;
  