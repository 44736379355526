import Menu from "../components/Menu";
import Container from "react-bootstrap/Container";
const Projects = () => {
    return(
      <>
      <Container>
      <Menu/>
       <div className="container-fluid p-5">
        <h1>Zrealizowane projekty</h1>
        <h2>Montaż systemu do kompensacji mocy biernej</h2>
        <p>Przykładowe realizacje</p>

       </div>
      </Container>      
       </>
    );
  };
  
  export default Projects;
  