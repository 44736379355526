import Card from 'react-bootstrap/Card';

function MJCard(props){
    return(
        <>
        <Card style={{ width: '17rem' }} className='shadow m-3'>
        <Card.Img variant="top" src={props.imgSrc} className='p-5' style={{ height: '14rem' }}/>
        <Card.Body>
            <Card.Title>{props.cardHeading}</Card.Title>
            <Card.Text>
            {props.cardText}
            </Card.Text>
        </Card.Body>
        </Card>
        </>
    );
}
export default MJCard;