import CarouselAPO from "../components/CarouselAPO";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image";

import MJCard from "../components/MJCard";
import Menu from "../components/Menu";
import icon1 from "../components/images/cog.svg";
import icon2 from "../components/images/robot.svg";
import icon3 from "../components/images/circuit.svg";
import icon4 from "../components/images/logistic.svg";

import image1 from "../components/images/shelf.webp";
import GradientDivider from "../components/GradientDivider";
import { Link } from "react-router-dom";


const Home = () => {
    return(
      <>
      <Container>
        <Menu/>
      </Container>
      
      
      <CarouselAPO/>
      <GradientDivider/> 

      <Container>
        <div className="container-fluid p-5">
            <h1 className="pt-2 pb-2">Witamy na naszej stronie!</h1>
            <div className="fs-5">
            <p>              
            Zajmujemy się obsługą firm w zakresie dostaw komponentów automatyki przemysłowej, 
            niezbędnych do utrzymania pracy maszyn i linii produkcyjnych. 
            Od wielu lat działamy w branży przemysłowej. 
            Duża wiedza i doświadczenie w doborze urządzeń technicznych sprawia, 
            że jesteśmy w stanie sprostać nawet największym wymaganiom. 
           </p>
           <p>
            Zgodnie z naszą domeną „Industry Support” - realnie wspieramy przemysł.
              
            </p>
           
            </div>
        </div>

        <div className="row d-flex justify-content-center pb-5">
            <MJCard imgSrc={icon4} cardHeading="Globalny łańcuch dostaw" cardText="Szybka realizacja trudno dostępnych komponentów automatyki przemysłowej."/>
            <MJCard imgSrc={icon1} cardHeading="Doradztwo techniczne" cardText="Potrzebujesz pomocy w doborze asortymenu? Dobrze trafiłeś!"/>
            <MJCard imgSrc={icon2} cardHeading="Profesjonalna obsługa" cardText="Załatwiamy tematy szybko i rzetelnie, zapraszamy do kontaktu."/>
            <MJCard imgSrc={icon3} cardHeading="Wysoka jakość" cardText="Współpracujemy tylko z najlepszymi dostawcami w branży."/>
        </div>
      </Container>   
          

      <div className="bg-theme-grad text-white p-5 border-bottom border-3 border-dark">
        <Container>
        <h2>Potrzebujesz pomocy w poszukiwaniu trudno dostępnych rzeczy?</h2>
        <p className="fs-4">
          <a href="tel:+48 577 607 177" className='text-decoration-none text-white bi'>
            Zadzwoń lub napisz do nas! Z przyjemnością Tobie pomożemy!
            </a></p>
        </Container>
        

      </div>

      <Container>
      <Container fluid className='p-5  '>
          <Row xs={1} md={2}>
              <Col className="pt-3 pb-3">
              <h3>APO-TECH Automatyka Przemysłowa</h3>
              <p className="fs-5">
              Mamy dostęp do największych magazynów w Polsce, całej Europie, jak również w Chinach,
               Wielkiej Brytanii i Stanach Zjednoczonych. 
               W ofercie posiadamy szeroki asortyment komponentów automatyki przemysłowej <Link to="/producers" onClick={() => window.scrollTo(0, 0)}>producentów</Link>.
              </p>
              
              <p className="fs-5">
              Jesteśmy obecni również na platformie <Link to="https://automa.net/company/apo-tech-pawel-orlowski/" target="_blank">automa.net</Link>.
              </p>
                
             
              <p className="fs-5">
                Zapraszamy do <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>kontaktu</Link> 
                </p>
              </Col>

              <Col className="pt-3 pb-3">
              <Image src={image1} fluid />
              </Col>

          </Row>
      </Container>
      </Container> 
       
       </>
    );
  };
  
  export default Home;
  