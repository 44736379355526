import Row from "react-bootstrap/Row";
import Menu from "../components/Menu";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Singleoffer from "../components/Singleoffer";

import offerImageElectric from '../components/images/offerimages/electric.webp';
import offerImageSensors from '../components/images/offerimages/sensors.webp';
import offerImageInverter from '../components/images/offerimages/inverter.webp';
import offerImageDrive from '../components/images/offerimages/drive.webp';
import offerImageControl from '../components/images/offerimages/control.webp';
import offerImageSafety from '../components/images/offerimages/safety.webp';
import offerImagePneumatics from '../components/images/offerimages/pneumatics.webp';
import offerImageHydraulics from '../components/images/offerimages/hydraulics.webp';
import offerImageRobotics from '../components/images/offerimages/robotics.webp';

const Offer = () => {
  const electricOfferText = ["Wyłączniki silnikowe", "Styczniki", "Zasilacze impulsowe", "Przekaźniki elektroniczne",
  "Szafy sterownicze", "Obudowy, rozdzielnie", "Złącza przemysłowe", "Przewody elektryczne"];

  const sensorsOfferText = ["Indukcyjne", "Pojemnościowe", "Fotoelektryczne", "Kurtyny bezpieczeństwa", "Enkodery",
  "Wyłączniki krańcowe"];

  const inverterOfferText = ["Skalarne", "Wektorowe", "Serwo", "Softstarty"];

  const driveOfferText = ["Silniki", "Serwomotory", "Przekładnie", "Motoreduktory", "Sprzęgła", "Elektrowrzeciona"];

  const controlOfferText = ["Sterowniki PLC", "Panele operatorskie HMI", "Moduły programowalne"];

  const safetyOfferText = ["Kurtyny bezpieczeństwa", "Przekaźniki bezpieczeństwa", "Wyłączniki drzwiowe", "Wyłączniki ryglujące",
  "Wyłączniki magnetyczne"];

  const pneumaticsOfferText = ["Siłowniki", "Elektrozawory", "Wyspy zaworowe", "Przygotowanie powietrza", "Technika połączeniowa",
  "Technika próżniowa", "Siłowniki elektryczne"];

  const hydraulicsOfferText = ["Pompy próżniowe", "Zawory do wody i pary", "Przepustnice", "Siłowniki sterujące", "Wyłączniki krańcowe",
  "Manometry", "Wakuometry"];

  const roboticsOfferText = ["Roboty współpracujące Coboty", "Siłowniki hydrauliczne", "Pompy hydrauliczne", "Klucze hydrauliczne"];

    return(
      <>
  
      <Container>
      <Menu/>
      <div className="container-fluid p-5 text-center">
        <h1>Nasza oferta</h1>
        <h2>Automatyka przemysłowa</h2>
        <h3>Dystrybucja i doradztwo techniczne</h3>

       </div>

       <Row xs={1} md={2} lg={3} className="text-center">
        <Col>
          <Singleoffer offerImage={offerImageElectric} offerHeading="Elektryka i elektronika" offerText={electricOfferText}/>
        </Col>
        <Col>
        <Singleoffer offerImage={offerImageSensors} offerHeading="Czujniki i systemy pomiarowe" offerText={sensorsOfferText}/>
        </Col>
       
        <Col>
        <Singleoffer offerImage={offerImageInverter} offerHeading="Falowniki, serwoprzetwornice" offerText={inverterOfferText}/>
        </Col>
    
        <Col>
        <Singleoffer offerImage={offerImageDrive} offerHeading="Technika napędowa" offerText={driveOfferText}/>
        </Col>
        <Col>
        <Singleoffer offerImage={offerImageControl} offerHeading="Sterowanie i wizualizacja" offerText={controlOfferText}/>
        </Col>
        <Col>
        <Singleoffer offerImage={offerImageSafety} offerHeading="Bezpieczeństwo" offerText={safetyOfferText}/>
        </Col>
        <Col>
        <Singleoffer offerImage={offerImagePneumatics} offerHeading="Pneumatyka" offerText={pneumaticsOfferText}/>
        </Col>
        <Col>
        <Singleoffer offerImage={offerImageHydraulics} offerHeading="Pompy, filtry, zawory" offerText={hydraulicsOfferText}/>
        </Col>
        <Col>
        <Singleoffer offerImage={offerImageRobotics} offerHeading="Roboty przemysłowe i hydraulika siłowa" offerText={roboticsOfferText}/>
        </Col>
       </Row>
      </Container>
      
       
       </>
    );
  };
  
  export default Offer;
  