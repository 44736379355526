import { Outlet } from "react-router-dom";
import Logo from "../components/Logo";
import Footer from "../layout/Footer";

function Layout(){
  return (
    <>
    <Logo />
    <Outlet />
    <Footer/>
    </>
  )
};

export default Layout;
