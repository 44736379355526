import Gridgallery from "../components/Gridgallery";
import Menu from "../components/Menu";
import Container from "react-bootstrap/Container";

import imageData from '../components/images/logos/imageData.json';
import { Row, Col } from "react-bootstrap";


const Producers = () => {
    return(
      <>
      <Container>
      <Menu/>
      <div className="container-fluid p-5 text-center">
        <h1>Nasi Partnerzy</h1>
       </div>
      <Gridgallery imageData={imageData} imagesPath="./images/logos/"/>
      
      <h2>Pełna lista producentów:</h2>
      <Row xs={2} md={3} lg={4} className="pt-3 pb-5" >
      <Col>ABB</Col>
      <Col>ALLEN-BRADLEY</Col>
      <Col>AKE</Col>
      <Col>ASCO</Col>
      <Col>ATEMAG</Col>
      <Col>AVENTICS</Col>
      <Col>B&R</Col>
      <Col>BALDOR</Col>
      <Col>BALLUFF</Col>
      <Col>BAUMER</Col>
      <Col>BAUMÜLLER</Col>
      <Col>BECKHOFF</Col>
      <Col>BERGER LAHR</Col>
      <Col>BESEL</Col>
      <Col>BONFIGLIOLI</Col>
      <Col>BOSCH REXROTH</Col>
      <Col>BÜRKERT</Col>
      <Col>CAMOZZI</Col>
      <Col>CONTROL TECHNIQUES</Col>
      <Col>CHIARAVALLI</Col>
      <Col>DANFOSS</Col>
      <Col>DATALOGIC</Col>
      <Col>EATON</Col>
      <Col>ENERPAC</Col>
      <Col>EUCHNER</Col>
      <Col>EURA</Col>
      <Col>FANUC</Col>
      <Col>FATEK</Col>
      <Col>FESTO</Col>
      <Col>FUJI</Col>
      <Col>FINDER</Col>
      <Col>HARTING</Col>
      <Col>HEIDENHAIN</Col>
      <Col>HF INVERTER</Col>
      <Col>HOMAG</Col>
      <Col>HONEYWELL</Col>
      <Col>IFM</Col>
      <Col>IGUS</Col>
      <Col>IMA SCHELLING GROUP</Col>
      <Col>KEB</Col>
      <Col>KEYENCE</Col>
      <Col>KOLLMORGEN</Col>
      <Col>KÜBLER</Col>
      <Col>KUKA</Col>
      <Col>LAPP KABEL</Col>
      <Col>LG-LS</Col>
      <Col>LENZE</Col>
      <Col>MEAN WELL</Col>
      <Col>METAL WORK</Col>
      <Col>MITSUBISHI</Col>
      <Col>MOELLER</Col>
      <Col>MOOG</Col>
      <Col>NUM</Col>
      <Col>NORD</Col>
      <Col>NORGREN</Col>
      <Col>OKUMA</Col>
      <Col>OMEC MOTORS</Col>
      <Col>OMRON</Col>
      <Col>ORIENTAL MOTOR</Col>
      <Col>PANASONIC</Col>
      <Col>PARKER</Col>
      <Col>PEPPERL+FUCHS</Col>
      <Col>PERSKE</Col>
      <Col>PHILIPS</Col>
      <Col>PHOENIX CONTACT</Col>
      <Col>PILZ</Col>
      <Col>PIZZATO</Col>
      <Col>PRO-FACE</Col>
      <Col>RECTUS</Col>
      <Col>RELPOL</Col>
      <Col>SELS</Col>
      <Col>SCHMERSAL</Col>
      <Col>SCHNEIDER ELECTRIC</Col>
      <Col>SEM</Col>
      <Col>SEW-EURODRIVE</Col>
      <Col>SICK</Col>
      <Col>SIEMENS</Col>
      <Col>SMC</Col>
      <Col>TELEMECANIQUE</Col>
      <Col>TURCK</Col>
      <Col>ULTRAFILTER</Col>
      <Col>WEIDMÜLLER</Col>
      <Col>WEINTEC</Col>
      <Col>WIKA</Col>
      <Col>YASKAWA</Col>
      </Row>
       

   
      </Container>
      
       </>
    );
  };
  
  export default Producers;
  