import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap';
import { useLocation } from "react-router-dom";

function Menu() {
    const location = useLocation();
  return (   
    <> 
  <Navbar expand="md" className='pt-5'>
    
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='mx-auto mb-3'/>
        <Navbar.Collapse id="basic-navbar-nav">

        <Nav fill justify  variant="tabs" className="container-fluid pe-0 d-flex flex-column flex-md-row" defaultActiveKey={location.pathname}>
            <Nav.Item>
                <LinkContainer to="/">
                <Nav.Link eventKey="/">Strona Główna</Nav.Link>
                </LinkContainer>
            </Nav.Item>
            <Nav.Item>
                <LinkContainer to="/offer">
                <Nav.Link eventKey="/offer">Oferta</Nav.Link>
                </LinkContainer>
            </Nav.Item>
            <Nav.Item>
                <LinkContainer to="/producers">
                    <Nav.Link eventKey="/producers">Producenci</Nav.Link>
                </LinkContainer>
            </Nav.Item>
           {/* <Nav.Item>
                <LinkContainer to="/projects">
                    <Nav.Link eventKey="/projects">Realizacje</Nav.Link>
                </LinkContainer>
            </Nav.Item>*/}
            <Nav.Item> 
                <LinkContainer to="/partners">
                    <Nav.Link eventKey="/partners">Zaufali nam</Nav.Link>
                </LinkContainer>
            </Nav.Item>
           {/*  <Nav.Item>
                <LinkContainer to="/aboutus">
                    <Nav.Link eventKey="/aboutus">O nas</Nav.Link>
                </LinkContainer>
            </Nav.Item>*/}
            <Nav.Item>
                <LinkContainer to="/contact">
                    <Nav.Link eventKey="/contact">Kontakt</Nav.Link>
                </LinkContainer>
            </Nav.Item>
            
        </Nav>

        </Navbar.Collapse>
      
    </Navbar>
    

    </>
     
  );
}

export default Menu;