import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function Contactform() {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>Adres email</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="subject">
        <Form.Label>Temat</Form.Label>
        <Form.Control type="text" placeholder="Temat wiadomości" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="text">
        <Form.Label>Wiadomość</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="humancheck">
        <Form.Check type="checkbox" label="Jestem człowiekiem" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="policycheck">
        <Form.Check type="checkbox" label="Akceptuję regulamin przetwarzania danych osobowych." />
      </Form.Group>
      <Button variant="primary" type="submit">
        Wyślij
      </Button>
    </Form>
  );
}

export default Contactform;